<template>
    <div class="statistics main">
        <ul class="tabs">
            <li :class="{tab:true,active:v.route_name===$route.name}" v-for="(v,k) in tab" :key="k"
                @click="tabChange(v.route_name)">{{v.name}}</li>
        </ul>
        <div class="container">
            <router-view />
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                tab:[
                    {
                        name:'综合查询',
                        route_name:'statistics_all',
                    },
                    {
                        name:'自筹项目查询',
                        route_name:'statistics_unplan',
                    }
                ]
            }
        },
        methods:{
            tabChange(name){
                if(name===this.$route.name) return;
                this.$router.replace({name:name})
            }
        }
    }
</script>

<style scoped>

</style>